import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Check } from 'react-feather'
import { Text } from 'rebass'
import { AutoColumn } from '../Column'
export const Card = styled.div`
  display: flex;
  border-radius: 8px;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
  `};
`

export const StepNumber = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.white};
  color: #666;
  text-align: center;
  margin-right: 16px;
  font-weight: 700;
  z-index: 2;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    position: absolute;
    top: -16px;
    left: 20px;
    border: solid 4px #F2F4F5;
    width: 34px;
    height: 34px;
  `};
`

const StepLine = styled.div<{ first: boolean; last: boolean }>`
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 15px;
  border-left: 2px solid ${({ theme }) => theme.colors.emphasisedBackground};
  margin-top: ${props => (props.first ? '0' : '-32px')};
  padding-bottom: 32px;
  -webkit-transform: translate(-50%);
  height: ${props => props.last && '0'};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const ThickCheck = styled(Check)`
  stroke-width: 3;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.text1};
`
const DoneStep = styled(StepNumber)`
  background: #00ffb9;
`

const DisabledStep = styled(StepNumber)`
  background: ${({ theme }) => theme.colors.disabled};
`

const ActiveStep = styled(StepNumber)`
  background: ${({ theme }) => theme.colors.text1};
`

export const CardStep = styled.div<{ disabled: boolean }>`
  flex: 5;
  padding: 24px;
  background: ${props => (props.disabled ? ({ theme }) => theme.colors.disabled : '#00FFB9')};
  border-radius: 8px 0 0 8px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-radius: 8px 8px 0 0;
    padding-top: 36px;
  `};
`
export const CardStepTitle = styled.div`
  font-size: 28px;
  line-height: 34px;
`
export const CardStepDescription = styled.div`
  font-size: 16px;
  line-height: 20px;
`
export const CardAction = styled.div`
  flex: 10;
  padding: 24px;
  border-radius: 0 8px 8px 0;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-radius: 0 0 8px 8px;
  `};

  background: ${({ theme }) => theme.colors.white};
`

export const CardActionTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text2};
`

const CardActionDetail = styled.div`
  background: transparent;
  z-index: 2;
  position: relative;
`

export const CardIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  img {
    width: 5rem;
  }
`
interface CardProps {
  title?: string
  stepTitle?: string
  description?: string
  children: React.ReactNode
  status: string
  imgSrc: string
  step: number
}

const getStepNumber = (status: string, stepNumber: number): React.ReactNode => {
  switch (status) {
    case 'DONE':
      return (
        <DoneStep>
          <ThickCheck />
        </DoneStep>
      )

    case 'DISABLED':
      return <DisabledStep>{stepNumber}</DisabledStep>
  }
  return <ActiveStep>{stepNumber}</ActiveStep>
}

export const StepsCard = ({ title, description, status, step, children, imgSrc, stepTitle, ...rest }: CardProps) => {
  const theme = useTheme()
  return (
    <>
      <Card>
        {getStepNumber(status, step)}
        <StepLine first={step === 1} last={step === 3} />
        <CardStep disabled={status === 'DISABLED'}>
          <CardStepTitle>{title}</CardStepTitle>
          <CardStepDescription>{description}</CardStepDescription>
        </CardStep>
        <CardAction>
          <CardActionDetail>
            <AutoColumn gap="16px">
              <Text color={theme.colors.text2}>{stepTitle}</Text>
              {children}
            </AutoColumn>
          </CardActionDetail>
          <CardIcon>
            <img src={imgSrc} alt="icon" />
          </CardIcon>
        </CardAction>
      </Card>
    </>
  )
}
