import { createGlobalStyle } from 'styled-components'

export const FixedGlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Acre';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/Acre-Regular.ttf) ;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Acre';
    font-style: normal;
    font-weight: 500;
    src: url(/fonts/Acre-Medium.ttf) ;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Acre';
    font-style: normal;
    font-weight: 700;
    src: url(/fonts/Acre-Bold.ttf) ;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Acre';
    font-style: normal;
    font-weight: 900;
    src: url(/fonts/Acre-Extrabold.ttf);
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Arca Majora 3';
    font-style: normal;
    font-weight: 700;
    src: url(/fonts/ArcaMajora3-Bold.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Arca Majora 3';
    font-style: normal;
    font-weight: 900;
    src: url(/fonts/ArcaMajora3-Heavy.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
    html, input, textarea, button {
      font-family: Acre, 'Arca Majora 3';
      letter-spacing: -0.018em;
      font-display: fallback;
    }
    @supports (font-variation-settings: normal) {
      html, input, textarea, button {
        font-family: Acre, 'Arca Majora 3';
      }
    }

    html,
    body {
      margin: 0;
      padding: 0;
    }

    * {
      box-sizing: border-box;
    }

    button {
      user-select: none;
    }
    
    a {
color: #6a6c6e;
}

    html {
      font-family: Acre, 'Arca Majora 3';
      font-size: 16px;
      font-variant: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
`

export const ThemedGlobalStyle = createGlobalStyle`
    html {
      color: ${({ theme }) => theme.colors.text1};
      background-color:${({ theme }) => theme.colors.background};
    }

    body {
      min-height: 100vh;
      background-position: 0 -30vh;
      background-repeat: no-repeat;
    }
`
