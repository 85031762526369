import base from './base'

export default {
  ...base,
  colors: {
    ...base.colors,
    shadow1: '#2F80ED',
    text1: '#3B4346',
    text2: '#616568',
    text3: '#888D9B',
    text4: '#C3C5CB',

    ifPrimary: '#050A5A',

    // backgrounds / greys
    background: '#F2F4F5',
    emphasisedBackground: '#E9EBEC',
    navigationBackground: '#F6F8F9',
    cardBackground: 'white',
    navItemBackground: '#E8EEFF',
    disabled: '#D0D5D6',
    bg1: '#FFFDFA',
    bg3: '#EDEEF2',
    bg4: '#CED0D9',
    bg5: '#43d3db',
    modalBG: 'rgba(0,0,0,0.3)',
    advancedBG: 'rgba(255,255,255,0.6)',

    //primary colors
    primary1: '#0074FF',
    primary4: '#0AC6E5',
    primary5: '#E6F9FC',

    // color text
    primaryText1: '#fff',
    buttonText: '#ffffff',

    // secondary colors
    secondary1: '#13aab5'
  }
}
