type TokenInformation = {
  address: string
  name: string
}

const tokenList: { [name: string]: TokenInformation } = {
  DAI: {
    address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    name: 'DAI'
  },
  BUSD: {
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    name: 'BUSD'
  },
  USDT: {
    address: '0x55d398326f99059ff775485246999027b3197955',
    name: 'USDT'
  },
  USDC: {
    address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    name: 'USDC'
  },
  IF: {
    address: '0xb0e1fc65c1a741b4662b813eb787d369b8614af1',
    name: 'IF'
  },
  IDIA: {
    address: '0x0b15ddf19d47e6a86a56148fb4afffc6929bcb89',
    name: 'IDIA'
  }
}

export default tokenList
