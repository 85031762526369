import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'STAX-BNB',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      56: '0x7FB0017195470bc6978659396eC9D750A35C51fE',
    },
    tokenSymbol: 'STAX',
    tokenAddresses: {
      97: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      56: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    multiplier: '5X',
    isPancake: true,
  },
  {
    pid: 4,
    lpSymbol: 'STAX-BUSD',
    lpAddresses: {
      97: '0x2f7682b64b88149ba3250aee32db712964de5fa9',
      56: '0x13AbFA7B781bEe80cA7FAe7Ec71045488d876A8d'
    },
    tokenSymbol: 'STAX',
    tokenAddresses: {
      97: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      56: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4'
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    multiplier: '5X',
    isPancake: true,
  },
  {
    pid: 18,
    lpSymbol: 'STAX-BNB',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      56: '0x0199bef6364fa22f107836ef47351e50585a5ada',
    },
    tokenSymbol: 'STAX',
    tokenAddresses: {
      97: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      56: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    multiplier: '5X',
    isCommunity: false,
  },
  {
    pid: 19,
    lpSymbol: 'STAX-BUSD',
    lpAddresses: {
      97: '0x2f7682b64b88149ba3250aee32db712964de5fa9',
      56: '0x4406fa5a7d32378691e151fb35fd5f4e2b45cd98'
    },
    tokenSymbol: 'STAX',
    tokenAddresses: {
      97: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      56: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4'
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    multiplier: '5X',
    isCommunity: false,
  },
]

export default farms
