import { MenuEntry } from '@impossiblefinance/uikit'

const config: MenuEntry[] = [
  {
    label: 'Invest',
    href: `${process.env.REACT_APP_FARMS_DOMAIN}/launchpad`,
    icon: 'InvestIcon',
    items: null
  },
  {
    label: 'Trade',
    href: `${process.env.REACT_APP_DOMAIN}/#/swap`,
    icon: 'SwapIcon',
    items: null
  },
  {
    label: 'Earn',
    icon: 'LiquidityIcon',
    items: [
      {
        label: 'Liquidity',
        href: `${process.env.REACT_APP_DOMAIN}/#/pool`
      }
    ]
  },
  {
    label: 'Migration',
    href: `/migrate`,
    isSidebar: true,
    isNew: true,
    icon: 'MigrationIcon',
    items: null
  }
]

export default config
