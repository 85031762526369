import { ChainId } from '@pancakeswap-libs/sdk'
import React from 'react'
import styled from 'styled-components'
import Modal from '../Modal'
import { ExternalLink, CloseIcon } from '../Shared'
import { Text } from 'rebass'
import { Player } from '@lottiefiles/react-lottie-player'
import Loading from '../Animation/loading.json'
import { RowBetween } from '../Row'
import { ButtonPrimary } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'

import { getEtherscanLink } from '../../utils'
import { useActiveWeb3React } from '../../hooks'

const Wrapper = styled.div`
  width: 100%;
  overflow-y: auto;
`
const Section = styled(AutoColumn)`
  padding: 24px;
`

const BottomSection = styled(Section)`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 28px 0;
`

export function ConfirmationPendingContent({
  onDismiss,
  pendingText,
  showClose = true
}: {
  onDismiss: () => void
  pendingText: string
  showClose?: boolean
}) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          {showClose && <CloseIcon onClick={onDismiss} />}
        </RowBetween>

        <AutoColumn gap="4px" justify={'center'}>
          <Text fontWeight={500} fontSize={24}>
            Waiting For Confirmation
          </Text>
          <ConfirmedIcon>
            <Player loop src={Loading} autoplay style={{ height: '135px', width: '135px' }} />
          </ConfirmedIcon>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={700} fontSize={15} textAlign="center">
              {pendingText}
            </Text>
          </AutoColumn>
          <Text fontSize={16} textAlign="center">
            Confirm this transaction in your wallet
          </Text>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  message
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  message?: string
}) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>

        <AutoColumn gap="4px" justify={'center'}>
          <Text fontSize={24}>Transaction Submitted</Text>
          <ConfirmedIcon>
            <img src="/images/Submitted.svg" alt="submitted" />
          </ConfirmedIcon>
          <Text fontSize={15} fontWeight={700}>
            {message}
          </Text>
          {chainId && hash && (
            <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>
              <Text fontSize={16} color="#3B4346" style={{ textDecoration: 'underline' }}>
                View on bscscan
              </Text>
            </ExternalLink>
          )}
          <ButtonPrimary onClick={onDismiss} style={{ margin: '20px 0 0 0', width: '220px' }}>
            Got it
          </ButtonPrimary>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            {title}
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        {topContent()}
      </Section>
      <BottomSection gap="12px" justify="center">
        {bottomContent()}
      </BottomSection>
    </Wrapper>
  )
}
export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <AutoColumn gap="24px" justify="center">
          <Text fontSize={24}>Transaction Failed</Text>
          <img src="/images/Warning.svg" alt="warning" />
          <Text fontWeight={500} fontSize={16} style={{ textAlign: 'center', width: '85%' }}>
            {message}
          </Text>
          <ButtonPrimary onClick={onDismiss} style={{ width: '220px' }}>
            got it
          </ButtonPrimary>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  message?: string
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  message
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React()

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent message={message} chainId={chainId} hash={hash} onDismiss={onDismiss} />
      ) : (
        content()
      )}
    </Modal>
  )
}
