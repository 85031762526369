import React, { useMemo } from 'react'
import Row from '../../components/Row'
import styled from 'styled-components'
import useAuth from '../../hooks/useAuth'
import { useWalletModal } from '@impossiblefinance/uikit'
import { StepsCard } from '../../components/StepsCard'
import { useActiveWeb3React } from '../../hooks'
import { ButtonLight } from '../../components/Button'
import { TranslateString } from '../../utils/translateTextHelpers'
import { Text } from 'rebass'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { Pair } from '@pancakeswap-libs/sdk'
import PoolCard from './PoolCard'
import { ExternalLink } from '../../components/Shared'

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 2rem 10rem;
  background: white;
  margin-top: -50px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 2rem 3rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 2rem;
    width: calc(100% + 32px);
    margin-top: 0;
  `};
`

export const HeaderTitle = styled.div`
  font-size: 28px;
  line-height: 34px;
`

export const HeaderDescription = styled.div`
  padding-top: 16px;
  margin-top: 8px;
  font-size: 14px;
  line-height: 18px;
`

export const MigrationCardWrapper = styled.div`
  margin-top: 24px;
  padding: 2rem 18rem;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 2rem 8rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 2rem 8rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0rem;
  `};
`

export const StepDivider = styled.div`
  height: 32px;
`

export default function Migration() {
  const { login, logout } = useAuth()
  const { account } = useActiveWeb3React()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  // staking
  const isFirstStepDone = true

  // LP
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )
  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const secondStepDone = !v2IsLoading && allV2PairsWithLiquidity.length === 0

  const renderNotConnected = () => {
    return (
      <>
        <div>
          <Text color="#A6A8AA">Connect wallet to view your staked IF LP tokens.</Text>
        </div>
        <ButtonLight onClick={onPresentConnectModal}>{TranslateString(292, 'Connect Wallet')}</ButtonLight>
      </>
    )
  }

  const STEPS = [
    {
      title: 'Remove Liquidity',
      stepTitle: 'Unstaked LP tokens in your wallet ',
      description: '',
      status: !account || !isFirstStepDone ? 'DISABLED' : isFirstStepDone && !secondStepDone ? 'ACTIVE' : 'DONE',
      step: 1,
      imgSrc: '/images/RemoveLP.svg',
      action: () => (
        <>
          {!account ? (
            renderNotConnected()
          ) : (
            <PoolCard isLoading={v2IsLoading} liquidity={allV2PairsWithLiquidity} disabled={!isFirstStepDone} />
          )}
        </>
      )
    }
  ]

  return (
    <>
      <HeaderWrapper>
        <Row>
          <HeaderTitle>Migration</HeaderTitle>
        </Row>
        <Row>
          <HeaderDescription>
            <Row>
              To migrate V2 liquidity to V3, please remove the V2 liquidity that is auto detected on this page, then
              continue to add V3 liquidity complete migration.
            </Row>
          </HeaderDescription>
        </Row>
      </HeaderWrapper>
      <MigrationCardWrapper>
        {STEPS.map(step => {
          return (
            <>
              <StepsCard {...step}>{step.action()}</StepsCard>
              <StepDivider />
            </>
          )
        })}
        Once you have removed V2 liquidity, you can start to{' '}
        <ExternalLink isInternal href={`${process.env.REACT_APP_DOMAIN}/#/pool`}>
          Add V3 Liquidity
        </ExternalLink>{' '}
        to complete the migration
      </MigrationCardWrapper>
    </>
  )
}
