export default {
  cake: {
    97: '0xEc4070F7Cb3Cb944Af729D8c23F3350dF24f1003',
    56:  '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4'
  },
  syrup: {
    97: '0xEc4070F7Cb3Cb944Af729D8c23F3350dF24f1003',
    56: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
  },
  masterChef: {
    97: '0x683B1d0CC12c8862Ce102773B4E8fFd6370d3eDA',
    56: '0xC80991F9106e26e43Bf1C07C764829a85f294C71',
  },
  sousChef: {
    97: '0x683B1d0CC12c8862Ce102773B4E8fFd6370d3eDA',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  wbnb: {
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  stax: {
    97: '0xEc4070F7Cb3Cb944Af729D8c23F3350dF24f1003',
    56:  '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4'
  },
  lottery: {
    97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
    56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  ust: {
    56: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    97: '',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '',
  },
}
