import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 508px;
  width: 100%;

  padding: 1rem 61px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem;
  `};
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 16px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
